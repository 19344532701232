import React from "react";
import maslow from "../assets/maslow.jpg";

export default () => {
  return (
    <>
      <img src={maslow} alt="Maslow's Hierarchy pyramid diagram." width={400} />
      <h1>What do I want?</h1>
      <p>The five needs are:</p>
      <h2>Physiological</h2>
      <ul>
        <li>food</li>
        <li>water</li>
        <li>sleep</li>
        <li>exercise</li>
        <li>music</li>
        <li>hugs {"&"} cuddles</li>
      </ul>
      <h2>Safety</h2>
      <ul>
        <li>seal your room</li>
        <li>exercise</li>
        <li>listen to relaxing music</li>
        <li>make a plan</li>
        <li>weighted blanket</li>
        <li>hugs {"&"} cuddles</li>
      </ul>
      <h2>Love and Belonging</h2>
      <ul>
        <li>talk to a friend</li>
        <li>go to a cafe</li>
        <li>go to a library</li>
        <li>go to a park</li>
        <li>hang out with someone</li>
        <li>tell someone you love them</li>
        <li>listen to poetry readings</li>
        <li>hugs {"&"} cuddles</li>
      </ul>
      <h2>Self-Esteem</h2>
      <ul>
        <li>study</li>
        <li>practice a skill</li>
        <li>clean room</li>
        <li>make a mood page</li>
        <li>go for a run</li>
        <li>journal</li>
        <li>make a plan to deal with the problem</li>
        <li>hugs {"&"} cuddles</li>
      </ul>
      <h2>Self-Actualization</h2>
      <ul>
        <li>dance</li>
        <li>read</li>
        <li>meditate</li>
        <li>go to gym</li>
        <li>practice creativity / expression (draw, write)</li>
        <li>review life goals</li>
        <li>learn a skill</li>
        <li>explore outside</li>
      </ul>
    </>
  );
};
